import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { css } from "@emotion/core"
import PageHeader from "../components/headers/page-header"
import SectionHeader from "../components/headers/section-header"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faHeadSideBrain,
  faBookDead,
  faMask,
  faTvRetro,
  faUfo,
  faUsersCrown,
} from "@fortawesome/pro-solid-svg-icons"
import { sortBy } from "lodash"

class PageResearch extends React.Component {
  render() {
    const { data } = this.props
    const research = data.allMarkdownRemark.edges

    // sort all mappings here first
    const mainTopics = [
      { topic: "mind control", icon: faHeadSideBrain, subtopics: [], node: null },
      { topic: "secret societies", icon: faMask, subtopics: [], node: null },
      { topic: "culture", icon: faTvRetro, subtopics: [], node: null },
      { topic: "technology", icon: faUfo, subtopics: [], node: null },
      { topic: "occult", icon: faBookDead, subtopics: [], node: null },
      { topic: "people", icon: faUsersCrown, subtopics: [], node: null },
    ]

    research.map(({ node }) => {
      const { topic } = node.frontmatter

      if (!topic) {
        console.error(`no topic for ${node.fields.slug}`)
        return null
      }

      let mainTopic = mainTopics.find((o) => {
        return o.topic === topic
      })
      if (mainTopic) {
        mainTopic.node = node
      } else {
        // figure out which main topics this is included in
        mainTopics.map((o) => {
          if (node.frontmatter.topics.includes(o.topic)) {
            o.subtopics.push(node)
          }
          return null
        })
      }

      return null

    })

    return (
      <Layout location={this.props.location}>
        <SEO
          title={'Research'}
          description={`Conspiracy Theories and Occult Knowledge that have inspired Paranoid American stories for original Comics and Graphic Novels.`}
        />

        <PageHeader>Research</PageHeader>

        <div
          css={css`
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            
            > div {
                color: #101010;
                flex: 0 50%;
                @media screen and (max-width: 740px) {
                  flex: 0 100%;
                }
                display: flex;
                
                > div {
                    flex: 0 100%;
                    position:relative;
                    margin: 20px;
                    padding: 0 0 10px;
                    p { margin: 20px; }
                }
            }
        `}>
          {mainTopics.map(({ node, topic, icon, subtopics }) => {

            // only show top-level topics / tags
            if (!node || !node.frontmatter.topic) {
              return null
            }

            subtopics = sortBy(subtopics, subtopic=> { return subtopic.fields.slug })

            return (
              <div key={node.fields.slug}>
                <div className={"drop-shadow"} css={css`display:flex;flex-direction: column;flex-wrap: nowrap;`}>
                  <Img
                    fluid={node.frontmatter.image.childImageSharp.fluid}
                    style={{
                      position: "absolute",
                      left: 0,
                      top: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  />

                  <Link to={node.fields.slug} css={css`position:relative;`}>
                    <SectionHeader align={"left"}>
                      <FontAwesomeIcon icon={icon} css={css`font-size:36px;margin:0 16px 0 8px;`}/>
                      {node.frontmatter.title}
                    </SectionHeader>
                  </Link>
                  <p
                    css={css`
                      position:relative;
                      background-color: rgba(255, 255, 255, 0.9);
                      padding:30px;
                      box-shadow:inset 0px 0px 4px #101010;
                      flex:1;
                  `}>
                    {node.frontmatter.description || node.excerpt}
                  </p>
                  <div css={css`
                    position: relative;
                    display:flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    margin: 0 20px;
                    justify-content: space-evenly;
                    > * { margin: 0 0 6px;}
                  `}>
                    {subtopics.map(node => {
                      return (
                        <Link key={node.fields.slug} to={node.fields.slug}><kbd>{node.frontmatter.topic}</kbd></Link>
                      )
                    })}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </Layout>
    )
  }
}

export default PageResearch

export const pageQuery = graphql`
    query {
        allMarkdownRemark(filter: {frontmatter: {type: {eq: "research"}}}) {
            edges {
                node {
                    fields {
                        slug
                    }
                    html
                    excerpt(pruneLength: 160)
                    frontmatter {
                        title
                        description
                        topic
                        topics
                        image {
                            name
                            childImageSharp {
                                fluid(maxWidth: 660, maxHeight: 560) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
